import WalletConnectProvider from "@walletconnect/web3-provider";
import mintAndList from '../contracts/PerseaSimpleCollection.json';
import detectEthereumProvider from '@metamask/detect-provider';
import Web3 from 'web3';
import axios from "axios";
import { appInfo } from "./app";
import { handleErrors } from "./messages";

const abis = {
    1 : mintAndList
}
const url = process.env.REACT_APP_URL_API+"blockchain?domain"+process.env.REACT_APP_DOMAIN

export const findAllByDomain = (domain) => {
    return axios.get(url+"?domain="+domain).then(res=>res.data)
}

export const findAllByName = async(domain,name) => {
    const blockchains = await findAllByDomain(domain)
    let newBlockchain = null;
    blockchains.forEach(blockchain => {
        if(blockchain.name.toUpperCase() === name.toUpperCase()) {
            newBlockchain = blockchain
        }
    })
    return newBlockchain
}

export const getWebInstance = (provider, rpc = null) => {
    let web3 = null;
    if (provider) {
        web3 = new Web3(provider);
    } else {
        if(rpc) {
            web3 = new Web3(rpc);
        } else {
            web3 = new Web3(process.env.REACT_APP_RPC);
        }
    }
    return web3
}

export const  sales = async(provider,to, rpc = null, contractType = 1) => {
    console.log(provider,to, rpc,contractType)
    let data = await appInfo()
    return data[process.env.REACT_APP_DOMAIN][to]['count_sales']
}

export const  contractSales = async(provider,to, rpc = null, contractType = 1) => {
    let web3 = getWebInstance(provider, rpc)
    let contract = new web3.eth.Contract(abis[contractType].abi,web3.utils.toChecksumAddress(to));
    let sales = await contract.methods.sales().call();
    console.log('sales', sales);
    return parseInt(sales);
}

export const priceWei = async(provider,to, rpc = null,contractType = 1) => {
    let web3 = getWebInstance(provider, rpc)
    let contract = new web3.eth.Contract(abis[contractType].abi,web3.utils.toChecksumAddress(to));
    let price = await contract.methods.currentPrice().call()
    console.log('price', price)
    return price
}

export const nextTokenId = async(provider,to, rpc = null,contractType = 1) => {
    const supply = totalSupply(provider,to, rpc,contractType);
    return supply + 1;
}

export const totalSupply = async(provider,to, rpc = null,contractType = 1) => {
    let web3 = getWebInstance(provider, rpc)
    let contract = new web3.eth.Contract(abis[contractType].abi,web3.utils.toChecksumAddress(to));
    let supply = await contract.methods.totalSupply().call();
    console.log('totalSupply', supply)
    return supply
}

export const price = async(provider,to, rpc = null,contractType = 1) => {
    console.log(provider,to, rpc,contractType)
    let data = await appInfo()
    return data[process.env.REACT_APP_DOMAIN][to]['price']
}

export const contractPrice = async(provider,to, rpc = null,contractType = 1) => {
    let web3 = getWebInstance(provider, rpc)
    let contract = new web3.eth.Contract(abis[contractType].abi,web3.utils.toChecksumAddress(to));
    let price = await contract.methods.currentPrice().call()
    console.log('price', price)
    return  web3.utils.fromWei(""+price,'ether')
}


export const getIfHaveTxt = async (domain, blockchain,from, gas = 'NONE') => {
    const url = `${process.env.REACT_APP_URL_API}/transaction?blockchain=${blockchain}&wallet=${from}&domain=${domain}&gasinfo=${gas}`
    return axios.get(url).then(res=>res.data)
}

export const validateTx = async(domain, blockchain, from,tx)=>{
    const url = `${process.env.REACT_APP_URL_API}/transaction?blockchain=${blockchain}&wallet=${from}&domain=${domain}&gasinfo=NONE&transaction=${tx}`
    return axios.get(url).then(res=>res.data)
}



export const payableMint = async (provider,blockchain, from, to, contractType = 1) => {
    console.log(provider,blockchain, from, to, contractType)
    let web3 = new Web3(provider);
    let contract = new web3.eth.Contract(
        abis[contractType].abi,
        web3.utils.toChecksumAddress(to)
    );
    try {
        const transaction = await contract.methods.payableMint(1).send({
            from: from,
            value: await priceWei(provider,to),
        });
        return transaction;
    } catch(error) {
        const errorFormat = handleErrors(error);
        const customError = new Error();
        customError.title = errorFormat.title;
        customError.message = errorFormat.message;
        customError.status = errorFormat.status;
        throw customError;
    }
};

const web3Connection =async(provider) => {
    const web3 = new Web3(provider)
    const chainID = await web3.eth.getChainId()
    const network = await web3.eth.net.getNetworkType()
    const accounts = await web3.eth.getAccounts()
    const wallet = accounts[0]
    if(chainID && new Web3().utils.toHex(chainID) !== new Web3().utils.toHex(process.env.REACT_APP_ETHEREUM_NETWORK)) {
        await addSiteBlockchainNetwork();
        await changeNetwork(provider, new Web3().utils.toHex(process.env.REACT_APP_ETHEREUM_NETWORK))
    }
    return {
        provider,
        chainID,
        network,
        wallet
    }
}

export const addSiteBlockchainNetwork = async () => {
    await addBlockchainNetwork(
        process.env.REACT_APP_ETHEREUM_NETWORK,
        process.env.REACT_APP_NAME_NETWORK,
        [process.env.REACT_APP_RPC],
        [process.env.REACT_APP_SCAN],
        "Matic",
        "MATIC",
        18,
        await detectEthereumProvider()
    );
}

export const addBlockchainNetwork = async (
    chainId,
    chainName,
    rpcUrls,
    blockExplorerUrls,
    currencyName,
    currencySymbol,
    currencyDecimals,
    provider,
) => {
    const customNetwork = {
        chainId: Web3.utils.toHex(chainId),
        chainName: chainName,
        rpcUrls: rpcUrls,
        nativeCurrency: {
            name: currencyName,
            symbol: currencySymbol,
            decimals: currencyDecimals,
        },
        blockExplorerUrls: blockExplorerUrls,
    };
    await provider.request({
        method: 'wallet_addEthereumChain',
        params: [customNetwork],
    });
}

export const loginWalletConnect = async () => {
    const provider = new WalletConnectProvider({
        infuraId: '3883f18c4ba04621abf087e8aeb5cb3d',
        rpc: {
            80001: `${process.env.REACT_APP_RPC}`,
            137: `${process.env.REACT_APP_RPC}`
        },
        qrcode: true
    });


    const rpc = String(process.env.REACT_APP_RPC);
    await provider.enable(rpc.includes("polygon") ? [137]:[80001]);

    return new Promise((resolve, reject) => {
        try {
            provider.on('disconnect', (code, reason) => {
                if (code === 1000) {
                    logout();
                } else {
                    console.error('Error al desconectar - Código:', code, 'Razón:', reason);
                }
            });
            web3Connection(provider).then((user) => {
                resolve(user);
            });
        } catch (error) {
            console.error('walletConnect error:', error);
            reject(error);
        }
    });
};

const logout = () => {
    window.location.reload();
};


export const sitePrivider = () => {
    return 
}

export const loginMetamask = async() => {
    const provider = await detectEthereumProvider().catch((err) =>{
        console.log("Error provider detectEthereumProvider()::",err);
     })
     await provider.request({ method: 'eth_requestAccounts' }).catch((error) => {
         console.log("debug  provider.request err",error);
   /*       if(error && error.code === -32002){
             setMsg("Please open your Wallet in your browser")
         } */
     });
     if(provider){
        try {
            return await web3Connection(provider);
        } catch(error) {
            localStorage.clear()
            throw error;
        }
    }

}


export  const changeNetwork = async (provider,chainId) => {
    return provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: new Web3().utils.toHex(chainId) }],
    })
}